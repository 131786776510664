import React, { useEffect, useState } from 'react';
import { fetchAllTips } from '../api/fetchTips';
import 'flag-icons/css/flag-icons.min.css';
import flags from '../flags.json';

const TipList = () => {
    const [tips, setTips] = useState([]);
    const [loading, setLoading] = useState(true);

    // Function to remove duplicates based on fixture_id
    const removeDuplicates = (allTips) => {
        const seen = new Set();
        return allTips.filter(tip => {
            const isDuplicate = seen.has(tip.fixture_id);
            seen.add(tip.fixture_id);
            return !isDuplicate;
        });
    };

    useEffect(() => {
        const loadTips = async () => {
            try {
                const allTips = await fetchAllTips(); // Fetch from all categories

                // Remove duplicates by fixture_id
                const uniqueTips = removeDuplicates(allTips);

                // Sort by date and time
                const sortedTips = uniqueTips.sort((a, b) => {
                    const dateA = new Date(`${a.date} ${a.time}`);
                    const dateB = new Date(`${b.date} ${b.time}`);
                    return dateA - dateB;
                });

                setTips(sortedTips);
            } catch (err) {
                setTips([]);
            } finally {
                setLoading(false);
            }
        };

        loadTips();
    }, []);

    const appendUTC2 = (time) => `${time} UTC+2hrs`;

    // Function to check if all bet values are 'no bet'
    const isNoBet = (tip) => {
        return (
            (!tip.over_bet || tip.over_bet === 'no bet') &&
            (!tip.under_bet || tip.under_bet === 'no bet') &&
            (!tip.btts_bet || tip.btts_bet === 'no bet') &&
            (!tip.safe_bet || tip.safe_bet === 'no bet') &&
            (!tip.default_bet || tip.default_bet === 'no bet') &&
            (!tip.risky_bet || tip.risky_bet === 'no bet')
        );
    };

    if (loading) {
        return <p>Loading tips...</p>;
    }

    if (tips.length === 0) {
        return <p>No tips available.</p>;
    }

    return (
        <div className="tip-list">
            {tips
                .filter(tip => !isNoBet(tip)) // Filter out tips where all bets are 'no bet'
                .map(tip => (
                    <div key={tip.fixture_id} className="tip-item">
                        <strong>{tip.home_team_name}</strong> vs <strong>{tip.away_team_name}</strong>
                        <br />
                        {/* Display the country flag */}
                        {['England', 'Scotland', 'Wales', 'Northern-Ireland'].includes(tip.country_name) ? (
                            <img 
                                src={`/flags/${flags[tip.country_name]}.png`} 
                                alt={tip.country_name} 
                                style={{ width: '24px', height: '18px', marginRight: '8px' }} 
                            />
                        ) : (
                            <span 
                                className={`fi fi-${flags[tip.country_name]?.toLowerCase() || 'default'}`} 
                                style={{ marginRight: '8px' }}>
                            </span>
                        )}
                        Country: {tip.country_name} | League: {tip.league_name}
                        <br />
                        Goals Over: {tip.over_bet || 'no bet'}
                        <br />
                        Goals Under: {tip.under_bet || 'no bet'}
                        <br />
                        Both Teams to Score: {tip.btts_bet || 'no bet'}
                        <br />
                        Safe Bet: {tip.safe_bet || 'no bet'}
                        <br />
                        Ordinary Bet: {tip.default_bet || 'no bet'}
                        <br />
                        Risky Bet: {tip.risky_bet || 'no bet'}
                        <br />
                        Date: {tip.date} | Time: {appendUTC2(tip.time)}
                    </div>
                ))}
        </div>
    );
};

export default TipList;
