import axios from 'axios';

const API_URL = 'https://betneh.com';  // Use your domain

// Fetch tips for multiple categories
export const fetchAllTips = async () => {
    const categories = ['safe', 'default', 'risky', 'over', 'under'];
    let allTips = [];

    try {
        // Fetch tips for each category and combine them into allTips
        for (const category of categories) {
            const response = await axios.get(`${API_URL}/api/tips/${category}`);
            if (response.data && response.data.length > 0) {
                allTips = [...allTips, ...response.data];
            }
        }
        return allTips;
    } catch (error) {
        console.error(`Error fetching tips:`, error);
        throw error;
    }
};
