import React from 'react';
import TipList from './components/TipList';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'flag-icons/css/flag-icons.min.css';


function App() {
  return (
    <div className="container">
      <h1 className="text-center my-4">Football Betting Tips</h1>
      <TipList category="safe" />
      <TipList category="default" />
      <TipList category="risky" />
      <TipList category="over" />
      <TipList category="under" />
      <p className="text-center my-4">Please gamble responsibly. Bet only what you can afford to lose.</p>
      <p className="text-center my-4">Need help? Contact GamCare for support at <a href="https://www.gamcare.org.uk/" target="_blank" rel="noopener noreferrer">www.gamcare.org.uk</a> or call their helpline at +44 808 8020 133.</p>
    </div>
  );
}

export default App;
